<template>
  <table class="rf-table" :style="tableStyle">
    <thead class="rf-table-head" v-if="items.length">
      <tr class="rf-table-fields">
        <th class="rf-table-field" v-for="(field, index) in tableFields" :key="`field-${index}`">
          {{field.label | splitOnUppercase}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="rf-table-row" v-for="(item, index) in items" :key="`item-${index}`">
        <td class="rf-table-data" :data-label="field.key | splitOnUppercase" v-for="(field, index) in tableFields" :key="`field-${index}`">
          <template v-if="item[field.key] === null">{{``}}</template>
          <template v-else-if="field.type === 'status'">
            <div class="status"
            :class="{
              ok: item[field.key] === paymentStatus.OK,
              ko: item[field.key] === paymentStatus.KO,
              pending: item[field.key] === paymentStatus.PENDING,
              scheduled: item[field.key] === paymentStatus.SCHEDULED,
            }">{{item[field.key]}}</div>
          </template>
          <template v-else-if="item[field.key] && item[field.key].toString() === 'true'">
            <FontAwesomeIcon :icon="['fas', 'check-circle']" color="#5CB85C"/>
          </template>
          <template v-else-if="item[field.key] && item[field.key].toString() === 'false'">
            {{``}}
          </template>
          <template v-else-if="field.type === 'cash'">
            {{item[field.key]}} &euro;
          </template>
          <template v-else-if="field.type === 'date'">
            {{item[field.key] | formatDate}}
          </template>
          <template v-else-if="field.type === 'datetime'">
            {{item[field.key] | formatDateTime}}
          </template>
          <template v-else-if="field.type === 'send'">
            <FontAwesomeIcon class="cursor-pointer" :icon="['fas', 'paper-plane']" color="#24425B" @click="$emit('send', item)"/>
          </template>
          <template v-else-if="field.type === 'boolean'">
            <FontAwesomeIcon :icon="['fas', 'check']" :color="'#5CB85C'" v-if="item[field.key]"/>
          </template>
          <template v-else>
            {{item[field.key]}}
          </template>
        </td>
      </tr>
    </tbody>
    <div class="empty-list" v-if="!items.length">
      {{$t('There are no items.')}}
    </div>
  </table>
</template>

<script>
import {paymentStatus} from '@/enums'

export default {
  name: 'RFTable',
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      paymentStatus,
      tableFields: [],
    }
  },
  beforeMount() {
    if (this.fields.length) {
      this.tableFields = this.fields
    } else {
      Object.keys(this.items[0]).forEach(key => this.tableFields.push({ label: key, key }))
    }
  },
  computed: {
    tableStyle() {
      return {
        '--column-width': `${100 / this.tableFields.length}%`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-table {
  width: 100%;
  table-layout: fixed;

  .rf-table-head {
    display: none;
  }
  .rf-table-row {
    display: block;
    margin: 20px 0;
    border: 1px solid $dark-blue;
    padding: 10px;
    color: $dark-blue;

    .rf-table-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include font-style($montserrat, 'semibold', $font-16);

      &:before {
        content: attr(data-label);
        @include font-style($montserrat, 'semibold', $font-14);
        text-transform: uppercase;
        float: left;
        color: $dark-blue;
      }
      .status {
        border-radius: 20px;
        text-align: center;
        color: $white;
        white-space: nowrap;
        text-transform: uppercase;
        padding: 0 10px;
        width: fit-content;
        background-color: $dark-blue;

        &.ok {
          background-color: $success;
        }
        &.ko {
          background-color: $danger;
        }
        &.scheduled {
          background-color: $green;
        }
        &.pending {
          background-color: $dark-blue;
        }
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .rf-table {
    .rf-table-head {
      display: block;
      text-transform: uppercase;
      color: $dark-blue;

      .rf-table-fields {
        display: flex;
        width: 100%;

        .rf-table-field {
          flex: 1 1 auto;
          width: var(--column-width);
        }
      }
    }
    .rf-table-head,
    .rf-table-row {
      @include font-style($montserrat, 'semibold', $font-14);
      text-align: center;
    }
    .rf-table-row {
      display: flex;
      border: none;
      margin: 0;
      padding: 10px 0;
      border-top: 1px solid $gray;

      &:first-child {
        margin-top: 20px;
      }
      .rf-table-data {
        flex: 1 1 auto;
        justify-content: center;
        width: var(--column-width);

        &:before {
          display: none;
        }
      }
    }
  }
}
</style>
