<template>
  <RFModal title="Reservation guests" :modalId="modalId" hide-footer>
    <RFTable class="mt-4" :items="guests" :fields="fields" />
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import RFTable from '@/components/forms/RFTable'

export default {
  name: 'AdminReservationGuestsModal',
  components: {
    RFModal,
    RFTable,
  },
  props: {
    guests: Array,
  },
  data() {
    return {
      modalId: 'reservation-guests-modal',
    }
  },
  computed: {
    fields() {
      return [
        { key: 'firstname', label: 'Firstname' },
        { key: 'lastname', label: 'Lastname' },
        { key: 'birthDate', label: 'Birth Date', type: 'date' },
        { key: 'nationality', label: 'Nationality' },
        { key: 'genre', label: 'Genre' },
        { key: 'email', label: 'Email' },
      ]
    }
  }
}
</script>

<style>

</style>