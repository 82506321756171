<template>
  <div>
    <div v-if="showReservationsTable" class="admin-all-reservations">
      <ReservationsTable title="All Apartment Reservations"
                  :list="reservationList" :fields="fields" :loading="loading" :availabilityButton="true" :manageAvailabilityButton="true"
                  @update-list="update" @open-modal="selectReservation" @edit="editReservation" @download="downloadContract" @show-availability="manageAvailabilityTables(false,false)" @manage-availability="manageAvailabilityTables(false,true)" @set-bank-transfer="setBankTransfer" @delete="cancelReservation"/>
      <ReservationGuestsModal :guests="guests"/>
    </div>
    <div v-else>
      <AdminAvailabilityTable title="All Apartments Availability"
                              :list="apartmentList" :loading="loading" :manageAvailability="manageAvailability" :isApartment="true"
                              @update-list="update" @show-reservations="manageAvailabilityTables(true,false)"/>
    </div>
  </div>
</template>

<script>
import ReservationsTable from '@/components/admin/ReservationsTable'
import AdminAvailabilityTable from '@/components/admin/AdminAvailabilityTable'
import ReservationGuestsModal from '@/components/admin/reservations/ReservationGuestsModal'
import { Reservation } from '@/models/reservation.js'

import { adminService, apartmentService } from '@/services/admin'
import { accommodations, docTypes, reservationStatus } from '@/enums'
import {docExtensions} from "../../../enums/document-extensions"
import { getAsset } from '@/helpers'

export default {
  name: 'AdminAllReservations',
  components: {
    ReservationsTable,
    AdminAvailabilityTable,
    ReservationGuestsModal,
  },
  data() {
    return {
      reservationList: [],
      reservations: [],
      apartmentList: [],
      loading: false,
      guests: [],
      showReservationsTable: true,
      adminAvailabilityTableTitle: "All Apartments Availability",
      manageAvailability:false
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Unit Name' },
        { key: 'apartment.addressCity', label: 'City' },
        { key: 'apartment.addressStreet', label: 'Address' },
        { key: 'apartment.floor', label: 'Floor' },
        { key: 'startDate', label: 'From', type: 'date' },
        { key: 'expiringDateAux', label: 'To', type: 'date' },
        { key: 'firstname', label: 'Firstname' },
        { key: 'lastname', label: 'Lastname' },
        { key: 'email', label: 'Email' },
        { key: 'status' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit', 'show', 'download', 'bt', 'delete'] },
      ]
    },
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    editReservation(reservation) {
      this.$store.commit('adminReservationStore/setReservation', reservation)
      this.$router.push({ name: 'edit-reservation' })
    },
    selectReservation(reservation) {
      this.guests = []
      this.guests = [...reservation.guests]
      this.guests.push(reservation.user)

      this.$root.$emit('bv::show::modal', 'reservation-guests-modal')
    },
    downloadContract(reservation) {
      const index = reservation.documents.findIndex(document => document.docType === docTypes.CONTRACT && (document.format === docExtensions.DOCX || document.format === docExtensions.PDF))
      const link = document.createElement("a")
      link.href = getAsset(reservation.documents[index].path)
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
    },
    async update() {
      console.log('Hello')
      this.loading = true
      this.reservationList = []
      let [reservationList, apartmentList] = await Promise.all([
        adminService.getReservationList(null, accommodations.APARTMENT.toUpperCase()),
        apartmentService.getApartments()
      ])

      this.apartmentList = apartmentList

      reservationList.forEach(element => {
        this.reservationList.push(new Reservation(element))
      });

      this.loading = false
    },
    async setBankTransfer(item) {
      await adminService.confirmBankTransferPaymentFromAdmin(item.id)
      await this.update()
    },
    async cancelReservation(reservationItem){
      const { data: reservation, status } = await adminService.updateReservation(reservationItem.id, reservationStatus.CANCELED)

      if (status === 200) {
        this.$toasted.success(this.$i18n.t(`Booking successfully canceled!`))
      } else {
        this.$toasted.error(this.$i18n.t('An error occurred'))
      }
      await this.update()
    },
    //RF-87
    manageAvailabilityTables(showOriginalTable,manageAvailability){
      this.showReservationsTable = showOriginalTable
      if(!this.showReservationsTable){
        this.manageAvailability = manageAvailability
        if(manageAvailability){
          this.adminAvailabilityTableTitle = 'Manage Apartments Availability'
        }
        else{
          this.adminAvailabilityTableTitle = 'All Apartments Availability'
        }
      }
      else{
        this.adminAvailabilityTableTitle = 'All Apartments Availability'
        this.manageAvailability = false
      }
    }
  }
}
</script>

<style>
</style>
